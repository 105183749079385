import { render, staticRenderFns } from "./post-list.vue?vue&type=template&id=6041e395&scoped=true&"
import script from "./post-list.vue?vue&type=script&lang=ts&"
export * from "./post-list.vue?vue&type=script&lang=ts&"
import style0 from "./post-list.vue?vue&type=style&index=0&id=6041e395&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6041e395",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
