





















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'post-list-item': () => import('./post-list-item.vue'),
    'rct-post-list-item': () => import('@/modules/recruitment/posts/rct-post-list-item.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class extends Vue {
  @Prop({}) vm!: any
  @Prop({ default: false, type: Boolean }) trending

  loadMore(entry) {
    if (this.vm.postsStore?.canLoadMorePost) {
      if (entry.isIntersecting) {
        this.vm.postsStore.loadMore()
      }
    }
  }

  syncFollowState(postStore) {
    const postStoreWithSameOwners = this.vm.postStores.filter((item) => item.post.id === postStore.post.id)
    postStoreWithSameOwners.map((item) => (item.post.isFollowing = postStore.post.isFollowing))
  }
}
